/*
 * @Date: 2022-03-25 12:00:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-15 14:55:53
 * @Description: file content
 * @FilePath: \user-center-frontend\src\utils\platform.js
 */
import {queryStringToMap, appTokenToPlatform, getParameterByName} from '../utils/qs';

export function getSaftWindow(key, target) {
  let w = window.top;
  let rs;
  try {
    if (target) {
      rs = target[key];
    } else {
      rs = w[key]
    }
  } catch {
    rs = window[key];
  }
  return rs;
}

function hasToken(appToken, appTokenList = []) {
  if (!appToken) {
    appToken = getParameterByName('appToken');
  }
  return appTokenList.some(t => t === appToken);
}

export function isAliPlatform() {
  const paramsMap = queryStringToMap();
  let appTokenVal =  paramsMap.get('appToken');
  let platformVal = appTokenToPlatform(appTokenVal);
  return platformVal === 'ALI1688';
}

/*
平台字段名叫 window.crm_platform_type
# ikcrm 爱客CRM独立版
# wxwork 企业微信版
# dingtalk 钉钉版
# lixiaoyun 励销云版
---------------------------
crm_app_type（app_type） 可以认为是 crm_platform_type 更加笼统的叫法，用 crm_platform_type 更准确
crm_app_type 具体划分
# ikcrm 爱客CRM
# ikcrm 励销云版
# wxwork 企微励销CRM
# dingtalk 钉钉爱客CRM
 */
export function getCrmContext() {
  let ctx = null;
  // console.log('ctx',ctx);
  // console.log('process.env.NODE_ENV',process.env.NODE_ENV);
  try {
    if(process.env.NODE_ENV === 'development') {
      window.crm_platform_type = "lixiaoyun";
      window.current_user_token = "5dd336120f4fd0b3f2e193b0760a82ee";
    }
    let w = window.top;
    if(isAliPlatform() || window?.platform_dingoxm){
      w = window;
    }
    const crm_platform_type = getSaftWindow('crm_platform_type', w);
    const crm_app_type = getSaftWindow('crm_app_type', w);
    if (crm_platform_type || crm_app_type || getSaftWindow('isDLJXC', w)) { // 表示嵌入在crm或进销存中
      ctx = {
        crmPlatformType: crm_platform_type || crm_app_type,
        userToken: getSaftWindow('current_user_token', w),
      };
    }
  } catch (e) {
    console.log(e);
  }
  // console.log('ctx',ctx);
  return ctx;
  // return ctx || {
  //   crmPlatformType: 'wxwork',
  //   userToken: '88c65220d80ddfa95401fdb5f95ed2af',
  // };
  // return ctx || {
  //   crmPlatformType: 'wxwork',
  //   userToken: '3e43613db08ca965d96e2219273beb70',
  // };
}

export function getUserToken() {
  const crmContext = getCrmContext();
  let w = window?.top;
  if(isAliPlatform() || window?.platform_dingoxm){
    w = window;
  }
  // console.log('crmContext',crmContext);
  // console.log('crmContext.userToken',crmContext?.userToken);
  // console.log('window.sessionStorage', window.sessionStorage.getItem('userToken'));
  // console.log('window.top.current_user_token', w?.current_user_token);
  // console.log('getParameterByName', getParameterByName("userToken"));
  // iframe嵌入的需要window顶层的数据
  return crmContext ? crmContext.userToken : window.sessionStorage.getItem('userToken') || getSaftWindow('current_user_token', w) || getParameterByName("userToken");
}

// 是否是独立版机器人
export function isIkRobot(appToken) {
  return hasToken(appToken, [
    'ee2b515eedc6d907bbfcf96c96b3734d',
    'f559e06d58c086bdc5560de4712e1cfd', // 生产
  ]);
}

// 判断是否是进销存
export function isJxc(appToken) {
  return hasToken(appToken, [
    'a329426fa9d79d058d1d3812a7e8bdf2',
    'ace93093b51aa0e8f7a38a0fa37eaad7',
    '2c8e775d76a90d9b066ec6d345df7c61',

    // 生产
    '6a99a826235f75de223cf5184caa0cc8',
    'ac2a065602491b9d247ea6d1d8f5a557',
    '6733a8381e3e8e131397612a5e9095db',
  ]);
}
