import wpsCollectParams from "../../config/wps/collect";
import CacheStorage from "./catchStorage";
import { getParameterByName } from "./qs";
import WpsLogo from "@assets/images/wps/wps.png";
import { changeFaviconUrl } from "./common";
import { isAliPlatform, getSaftWindow } from '../utils/platform';

const roleNames = {
  创建者: "owner",
  系统管理员: "owner",
  超级管理员: "owner",
  其他管理员: "admin",
  子管理员: "admin",
};

/**
 * @description wps过期错误码
 * */
export const overdueCode = [200171];

/**
 * @description: 上报埋点 collectWps*
 * @see https://www.kdocs.cn/l/ciFtbHU19UyU
 * @description
 * license_type 版本类别: 0免费版free, 1付费版vip, 2试用版trial
 * @example
 * NOTLOGINSHOW: '未登录页面展示'
 * NOTLOGINTOLOGIN: '未登录页点击立即登录',
 * LOGINSUCCESS: '登录成功',
 * AUTHORIZATIONSHOW: '授权页面展示',
 * AUTHORIZATIONAGREE: '授权-同意按钮点击',
 * AUTHORIZATIONSUCCESS: '授权成功',
 * AUTHORIZATIONNOTAGREE: '授权-拒绝按钮点击',
 * USERAGREEMENTSHOW: '用户协议页面展示',
 * USERAGREEMENTAGREE: '用户协议按钮-同意按钮点击',
 * USERLISTSHOW: '成员管理页面展示',
 * INVITECLICK: '点击邀请成员',
 * INVITESHOW: '邀请成员弹窗展示',
 * AUDITTIP: '名额不足弹窗提示',
 * COMPANYSHOW: '公司信息页面展示',
 * EXPIRESHOW: '已到期弹窗展示',
 * EXPIRECLICK: '已到期弹窗点击立即购买',
 * USERLEVELUP: '成员管理-立即升级按钮点击',
 * USERRENEW: '成员管理-续期时长
 * @description
 * _group_id(团队id) 用户当前登录的团队id
 * @description
 * _trail_process(试用期进度) 上报当前团队所处的试用期天数，
 * 当天创建的团队上报0，昨天创建的团队今天登录上报1……以此类推，若当前团队试用期已过，则上报expire
 * @description
 * _paid_status(付费情况)上报当前团队的付费情况：
 * 在演示版本上报experience 试用期内上报trial，试用期后依旧免费使用的上报free，购买了付费版本则上报各项目对应套餐名称版本
 * @description
 * _group_role(团队角色) 上报当前登录用户在当前所在团队的角色，
 * 创建者/系统管理员/超级管理员上报owner，其他管理员/子管理员上报admin，其他角色上报member，如果有多个不同的角色则拼接一并上报，若角色被转让，则在下次上报时更改；
 */

 export const collectWps = (name, params) => {
  new Promise((resolve) => {
    if (!window.top.wps) {
      window.top.document.addEventListener(
        "WpsofficeSDKReady",
        () => {
          if (!window.top._dw) {
            let interver = setInterval(() => {
              let dw = window?.top?.dw;
              if (dw.setDebug) {
                clearInterval(interver);
                resolve(true);
              }
            }, 600);
          } else {
            resolve(true);
          }
        },
        false
      );
    } else {
      if (!window.top._dw) {
        let interver = setInterval(() => {
          let dw = window?.top?.dw;
          if (dw.setDebug) {
            clearInterval(interver);
            resolve(true);
          }
        }, 600);
      } else {
        resolve(true);
      }
    }
  })
    .then(() => {
      let event = () => {
        let dw = window?.top?.dw
        // 防止crm系统再次引入
        !window?.dw_key &&
          (dw.setAppKey("d2c1a458c5aa631b"),
          (window.dw_key = "d2c1a458c5aa631b"));
        // dw.setDebug(true);
        dw.setDebug(
          ["SX20220218IEHISC"].includes(AppConf.wpsAppId)
        );
        let ssionData = new CacheStorage(false);
        let dw_param = ssionData.getData("dw_param") || {}; // 存储在session供搜客宝使用
        const org_packages = window?.org_packages || {}; // 获取套餐信息
        let probationProgress = ""; // 试用期进度
        let createdTime = org_packages?.createdAt; // 创建日期
        let propertySet = sessionStorage.getItem("dw_paid_status") || ""; // 搜客宝套餐名称或套餐属性
        let dw_trail_process = sessionStorage.getItem("dw_trail_process") || ""; // 搜客保是否过期
        if (createdTime && ["trial", "free"].includes(propertySet)) {
          // 体验和免费版
          if (!dw_trail_process) {
            // 搜客宝没有过期
            let nowDate = +new Date();
            createdTime = +new Date(createdTime).setHours(0);
            probationProgress =
              nowDate < createdTime
                ? "expire"
                : Math.floor((nowDate - createdTime) / 86400000);
          }
          // 搜客宝已过期
          else {
            probationProgress = dw_trail_process;
          }
        }
        let aid = sessionStorage.getItem("openId") || window.openid;
        if (aid) {
          dw.setAccountId(aid);
        }
        if (dw_param._app_version) {
          dw.setVersion(dw_param._app_version),
            dw.setChannel(dw_param._channel);
        }
        let wpsTrackInfoDw = window?.top?.wpsTrackInfoDw || {}
        // wps修改getLaunchOptions方法 新增src字段
        const realSrc = window?.top?.wps_launchOptions?.src || window?.top?.wps_launchOptions?.scene || ''
        dw_param = {
          ...dw_param,
          ...wpsTrackInfoDw && {
            src: realSrc,
            ...wpsTrackInfoDw,
          },
          ...!wpsTrackInfoDw && {
            src: realSrc,
            _group_id: window.sessionStorage.getItem("wps_group_id") || window.top?.wpsRes?.group_id,
            _group_role:
              roleNames?.[window.sessionStorage.getItem("dw_group_role")],
            _paid_status: params?._paid_status || propertySet,
            _trail_process: probationProgress,
          }
        };
        const attributes = {
          url: location.href,
          _open_platform_appid: getWpsAppid(),
          ...dw_param,
          ...wpsCollectParams?.[name],
          ...params,
          ...(aid && {
            _account_id: aid,
            _aid: aid,
          }),
        };
        ssionData.setData({ dw_param });
        console.log(attributes, "attributes");
        dw.onEvent("skb_data", attributes);
      };
      event();
    })
    .catch((err) => {
      console.log(err, "err========================>>>>>>>>>>>埋点");
    });
};

/**
 * @description 初始化wps
 */
const wpsDefaultInit = () => {
  changeFaviconUrl("wps", WpsLogo);
  const wps = window.top.wps;
  require("@/sdks/wps/collectWps");
  wps.getAppVersion({
    complete: (res) => {
      window.top.wps_version = res.data?.app_version;
    },
  });
  wps.getLaunchOptions({
    complete: (res) => (window.top.wps_launchOptions = res.data),
  });
};

/**
 * @description 确保wps环境下wps正确加载
 * @todo window赋值wps_version, wps_launchOptions, wps_systemInfo
 * */
export const installWps = (callback) => {
  let w = window.top;
  if(isAliPlatform() || window?.platform_dingoxm){
    w = window;
  }
  if (getSaftWindow('wps_id', w)) {
    changeFaviconUrl("wps", WpsLogo);
  }
  if (!getSaftWindow('wps', w)) {
    getSaftWindow('document', w).addEventListener(
      "WpsofficeSDKReady",
      callback ? callback : wpsDefaultInit,
      false
    );
  } else {
    callback ? callback() : wpsDefaultInit();
  }
};

/**
 * @desc 获取appid crm会丢失id 存成null值
 */
export const getWpsAppid = () => {
  if (getParameterByName("appid") && getParameterByName("appid") !== "null") {
    return getParameterByName("appid");
  } else if (
    getSaftWindow('appid') &&
    String(getSaftWindow('appid'))?.trim() !== "null"
  ) {
    return window.top.appid;
  } else {
    return AppConf.wpsAppId;
  }
};

export const setTopAlert = () => {
  window.top.Talert = function (msg, callback, href) {
    var div = getSaftWindow('document').createElement("div");
    div.innerHTML =
      '<style type="text/css">' +
      ".nbaMask { position: fixed; z-index: 1001; top: 0; right: 0; left: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); }                                                                                                                                                                       " +
      ".nbaMaskTransparent { position: fixed; z-index: 1000; top: 0; right: 0; left: 0; bottom: 0; }                                                                                                                                                                                            " +
      ".nbaDialog { position: fixed; z-index: 5000; width: 80%; max-width: 300px; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); background-color: #fff; text-align: center; border-radius: 8px; overflow: hidden; opacity: 1; color: white; }" +
      ".nbaDialog .nbaDialogHd { padding: .2rem .27rem .08rem .27rem; }                                                                                                                                                                                                                         " +
      ".nbaDialog .nbaDialogHd .nbaDialogTitle { font-size: 17px; font-weight: 400; }                                                                                                                                                                                                           " +
      ".nbaDialog .nbaDialogBd { padding: 0 .27rem; font-size: 15px; line-height: 1.3; height: 50px; line-height: 50px; word-wrap: break-word; word-break: break-all; color: #000000; }                                                                                                                                          " +
      ".nbaDialog .nbaDialogFt { position: relative; line-height: 48px; font-size: 17px; display: -webkit-box; display: -webkit-flex; display: flex; }                                                                                                                                          " +
      '.nbaDialog .nbaDialogFt:after { content: " "; position: absolute; left: 0; top: 0; right: 0; height: 1px; border-top: 1px solid #e6e6e6; color: #fc8352; -webkit-transform-origin: 0 0; transform-origin: 0 0; -webkit-transform: scaleY(0.5); transform: scaleY(0.5); }               ' +
      ".nbaDialog .nbaDialogBtn { display: block; -webkit-box-flex: 1; -webkit-flex: 1; flex: 1; color: #fc8352; text-decoration: none; -webkit-tap-highlight-color: transparent; position: relative; margin-bottom: 0; }                                                                       " +
      '.nbaDialog .nbaDialogBtn:after { content: " "; position: absolute; left: 0; top: 0; width: 1px; bottom: 0; border-left: 1px solid #e6e6e6; color: #e6e6e6; -webkit-transform-origin: 0 0; transform-origin: 0 0; -webkit-transform: scaleX(0.5); transform: scaleX(0.5); }             ' +
      ".nbaDialog a { text-decoration: none; -webkit-tap-highlight-color: transparent; }" +
      "</style>" +
      '<div id="dialogs2" style="display: none">' +
      '<div class="nbaMask"></div>' +
      '<div class="nbaDialog">' +
      '    <div class="nbaDialogHd">' +
      '        <strong class="nbaDialogTitle"></strong>' +
      "    </div>" +
      '    <div class="nbaDialogBd" id="dialog_msg2">弹窗内容，告知当前状态、信息和解决方法，描述文字尽量控制在三行内</div>' +
      '    <div class="nbaDialogHd">' +
      '        <strong class="nbaDialogTitle"></strong>' +
      "    </div>" +
      '    <div class="nbaDialogFt">' +
      `<a href="${
        href ? href : "javascript:;"
      }" class="nbaDialogBtn nbaDialogBtnPrimary" id="dialog_ok2">确定</a>` +
      "    </div></div></div>";
    
    const doc = getSaftWindow('document')
    doc.body.appendChild(div);

    var dialogs2 = doc.getElementById("dialogs2");
    dialogs2.style.display = "block";

    var dialog_msg2 = doc.getElementById("dialog_msg2");
    dialog_msg2.innerHTML = msg;
    var dialog_ok2 = doc.getElementById("dialog_ok2");
    dialog_ok2.onclick = function () {
      dialogs2.style.display = "none";
      callback && callback();
    };
  };
};
